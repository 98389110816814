import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Volunteering.css';

function Volunteering() {
  const opportunities = [
    {
      id: 1,
      title: "Event Coordinator",
      description: "Help us organize and run our events, ensuring they are enjoyable for all attendees.",
      image: "event-coordinator.jpg" // Replace with the path to your image
    },
    {
      id: 2,
      title: "Restoration Volunteer",
      description: "Join our restoration team to work on vintage vehicles and help preserve transportation history.",
      image: "restoration-volunteer.jpg" // Replace with the path to your image
    },
    {
      id: 3,
      title: "Tour Guide",
      description: "Become a tour guide and share your passion for transportation heritage with visitors.",
      image: "tour-guide.jpg" // Replace with the path to your image
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <div className="volunteering-header text-center">
        <h1>Volunteer with Tyne Tees Heritage Transport</h1>
        <p>Your time and skills can make a big difference in preserving our heritage.</p>
        <Button variant="primary" size="lg" href="#opportunities">Get Involved</Button>
      </div>

      {/* Why Volunteer Section */}
      <section className="why-volunteer-section mt-5">
        <Row>
          <Col md={6}>
            <h2>Why Volunteer?</h2>
            <p>Volunteering with us is a rewarding way to contribute to the community, gain new skills, and meet like-minded people. Whether you're passionate about history, love working with people, or want to learn something new, there's a place for you in our team.</p>
          </Col>
          <Col md={6}>
            <h2>Benefits of Volunteering</h2>
            <ul>
              <li>Make a positive impact on preserving transportation heritage.</li>
              <li>Gain hands-on experience in event planning, restoration, and more.</li>
              <li>Meet new friends and become part of a vibrant community.</li>
              <li>Enjoy exclusive volunteer events and recognition.</li>
            </ul>
          </Col>
        </Row>
      </section>

      {/* Volunteering Opportunities Section */}
      <section id="opportunities" className="volunteering-opportunities-section mt-5">
        <h2 className="text-center">Current Volunteering Opportunities</h2>
        <Row className="mt-4">
          {opportunities.map(opportunity => (
            <Col md={4} key={opportunity.id} className="mb-4">
              <Card className="volunteering-card">
                <Card.Img variant="top" src={opportunity.image} alt={opportunity.title} />
                <Card.Body>
                  <Card.Title>{opportunity.title}</Card.Title>
                  <Card.Text>{opportunity.description}</Card.Text>
                  <Button variant="primary" href="#">Apply Now</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
}

export default Volunteering;
