import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './AboutUs.css'; // Custom styles for About Us page
import '../images/Open_Day.jpg';
import '../images/Welding.jpg';

function AboutUs() {
  return (
    <Container>
      {/* Header Section */}
      <div className="about-header text-center">
        <h1>About Tyne Tees Heritage Transport</h1>
        <p>Preserving the rich history of transportation in the Tyne Tees region.</p>
      </div>

      {/* Mission Statement Section */}
      <section className="mission-section mt-5">
        <Row>
          <Col md={6}>
            <Image
              src={require('../images/Open_Day.jpg')} 
              fluid
              alt="Open Day"
            />
          </Col>
          <Col md={6}>
            <h2>Our Mission</h2>
            <p>
            We ignite a passion for the rich heritage of the North of England by acquiring, preserving, and showcasing historic vehicles and artifacts. Through education and inspiration, we bring the past to life, celebrating the region’s unique cultural history. By collaborating with other transport groups, we use our resources for the benefit of the local community, ensuring these treasures remain a vibrant part of our shared story.
            </p>
          </Col>
        </Row>
      </section>

      {/* History Section */}
      <section className="history-section mt-5">
        <Row>
          <Col md={6} className="order-md-2">
            <Image
              src={require('../images/Welding.jpg')} 
              fluid
              alt="Our History"
            />
          </Col>
          <Col md={6} className="order-md-1">
            <h2>Our History</h2>
            <p>
              Tyne Tees Heritage Transport was founded in ? by a group of transport enthusiasts who recognized the
              importance of preserving the region's rich transportation history. Over the years, we have grown from a
              small group of volunteers to a thriving organization that hosts events, educational programs, and more. We
              have successfully restored numerous vehicles and continue to expand our collection.
            </p>
          </Col>
        </Row>
      </section>

      {/* Team Section */}
      <section className="team-section mt-5 mb-5">
        <h2 className="text-center">Meet Our Team</h2>
        <Row className="mt-4 mb-5">
          <Col md={4}>
            <Card className="team-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=John+Kelly" />
              <Card.Body>
                <Card.Title>John Kelly</Card.Title>
                <Card.Text>Chairman</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="team-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Rob+Evans" />
              <Card.Body>
                <Card.Title>Rob Evans</Card.Title>
                <Card.Text>Vice Chairman</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="team-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Stan+Stanger" />
              <Card.Body>
                <Card.Title>Stan Stanger</Card.Title>
                <Card.Text>Treasurer</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Card className="team-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Trevor+Hines" />
              <Card.Body>
                <Card.Title>Trevor Hines</Card.Title>
                <Card.Text>To Be Confirmed</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="team-card text-center">
              <Card.Img variant="top" src="https://via.placeholder.com/150/35495e/ffffff?text=Trevor+Hines" />
              <Card.Body>
                <Card.Title>David Warren</Card.Title>
                <Card.Text>To Be Confirmed</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default AboutUs;
