import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Events.css';

function Events() {
  const events = [
    {
      id: 1,
      title: "Vintage Car Rally",
      date: "September 12, 2023",
      location: "Tyne Tees Park",
      description: "Join us for a spectacular display of vintage cars and enjoy a day full of activities, food stalls, and live music.",
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Event"  // Replace with the path to your image
    },
    {
      id: 2,
      title: "Heritage Bus Tour",
      date: "October 5, 2023",
      location: "City Center",
      description: "Take a ride on our restored heritage buses and explore the city's rich history with guided tours and commentary.",
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Event"  // Replace with the path to your image
    },
    {
      id: 3,
      title: "Steam Train Experience",
      date: "November 20, 2023",
      location: "Old Railway Station",
      description: "Experience the charm of steam-powered locomotives with a day of train rides, history exhibitions, and family-friendly activities.",
      image: "https://via.placeholder.com/150/35495e/ffffff?text=Event" // Replace with the path to your image
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <div className="events-header text-center">
        <h1>Upcoming Events</h1>
        <p>Explore our upcoming events and join us to celebrate the rich heritage of transportation.</p>
      </div>

      {/* Events Section */}
      <section className="events-section mt-5">
        <Row>
          {events.map(event => (
            <Col md={4} key={event.id} className="mb-4">
              <Card className="event-card">
                <Card.Img variant="top" src={event.image} alt={event.title} />
                <Card.Body>
                  <Card.Title>{event.title}</Card.Title>
                  <Card.Text><strong>Date:</strong> {event.date}</Card.Text>
                  <Card.Text><strong>Location:</strong> {event.location}</Card.Text>
                  <Card.Text>{event.description}</Card.Text>
                  <Button variant="primary" href="#">Learn More</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
}

export default Events;
